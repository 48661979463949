<template>
  <div class="d-inline-block">
    <b-button
      class="list-button"
      variant="outline-primary"
      size="sm"
      @click="getActivityLogs"
      title="show history"
    >
      <b-icon icon="clock-history" class="list-button" />
    </b-button>
    <b-modal v-model="modalList" size="lg" centered scrollable hide-footer>
      <b-table-lite
        v-if="list.length"
        :items="items"
        :fields="fields"
        bordered
        striped
      >
        <template #cell(actionDatetime)="row">
          {{ row.value | datetimelong }}
        </template>
        <template #cell(showDetails)="row">
          <b-button
            v-if="row.item.action === 'Edit'"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template #row-details="row">
          <b-row v-for="change in row.item.comparedObj" :key="change.key">
            <b-col>
              <small class="mr-2 d-block">{{ change.key }}</small>
              <small class="mr-2 text-warning">[{{ change.old }}]</small>
              <b-icon-arrow-right class="mr-2" />
              <small class="mr-2 text-success">[{{ change.new }}]</small>
            </b-col>
          </b-row>
        </template>
      </b-table-lite>
      <span v-else>There is no recent history</span>
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';
import _ from 'lodash';
// import JsonViewer from '@/components/UI/JsonViewer';

export default {
  name: 'BotActivityLog',
  props: {
    botId: Number,
  },
  mixins: [HttpHandler],
  // components: { JsonViewer },
  data() {
    return {
      list: [],
      modalList: false,
      fields: [
        {
          key: 'actionDatetime',
          label: 'Datetime',
        },
        {
          key: 'userName',
          label: 'Username',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'showDetails',
          label: '',
        },
      ],
    };
  },
  computed: {
    items() {
      let list = _.map(this.list, (log) => {
        let logObj = _.pick(log, [
          'actionDatetime',
          'userName',
          'action',
          'data',
        ]);
        const dataObj = JSON.parse(logObj.data);
        const propsToBeOmitted = ['AccountId', 'ClientTimeout'];
        let oldObj = { ...dataObj.Old };
        let newObj = { ...dataObj.New };
        let compareObj = _.omit(oldObj, propsToBeOmitted);
        let comparedObj = [];
        _.each(compareObj, (value, key) => {
          let oldValue = value;
          let newValue = newObj[key];
          //handle case when it is json string
          if (key === 'CustomValues') {
            oldValue = JSON.stringify(JSON.parse(oldValue));
            newValue = JSON.stringify(JSON.parse(newValue));
          }

          if (oldValue !== newValue) {
            //handle case for images
            const propsForImages = [
              'BotAvatar',
              'BotBanner',
              'BotBannerPotraitMode',
            ];
            if (propsForImages.includes(key)) {
              oldValue = '-';
              newValue = 'changed';
            }

            comparedObj.push({ key: key, old: oldValue, new: newValue });
          }
        });
        logObj.comparedObj = comparedObj;
        return logObj;
      });
      return list;
    },
  },

  methods: {
    async getActivityLogs() {
      let resp = await this.httpclient.get(
        `api/bots/${this.botId}/bot-activity-logs`
      );
      this.list = resp.data;
      this.modalList = true;
    },
  },
};
</script>

<style scoped>
.list-button {
  padding: 1px 3px;
}

.list-button:hover {
  color: #fff;
}

/*.list-button:hover {*/
/*  border: 1px solid #00b6f0;*/
/*  border-radius: 2px;*/
/*  padding: 2px;*/
/*}*/
</style>
