<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1 class="d-inline mr-3">Bot Settings</h1>
        <bot-activity-log class="align-top pt-3" :botId="formItem.botId" />
      </b-col>
      <b-col cols="3" class="mt-sm-3">
        <bot-selector @botChanged="changeBot" />
      </b-col>
    </b-row>

    <b-form ref="main-form" @submit.stop.prevent="promptConfirm">
      <b-row>
        <b-col cols="12" xl="8">
          <hr />
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Bot Name</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-input
                v-focus
                class="form-control-sm"
                v-model.trim="formItem.botName"
              ></b-input>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Bot Language</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-form-select
                size="sm"
                v-model="formItem.botLanguage"
                :options="languages"
                value-field="languageId"
                text-field="languageName"
                @change="changeLanguage"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Member Permission</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-form-checkbox
                v-model="formItem.allowMemberDownloadHistory"
                :value="true"
                :unchecked-value="false"
                >Allow member to download chat history?
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Offline Feedback Form</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-form-checkbox
                v-model="formItem.enableOfflineForm"
                :value="true"
                :unchecked-value="false"
                >Allow member to fill Offline Feedback Form?
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-show="formItem.enableOfflineForm" class="my-2">
            <b-col cols="12" xl="3">
              <label></label>
            </b-col>
            <b-col cols="12" xl="9" class="pl-5">
              <b-form-group>
                <b-form-radio
                  :class="{
                    'text-muted': formItem.useExternalLinkForOfflineForm,
                  }"
                  v-model="formItem.useExternalLinkForOfflineForm"
                  name="useExternalLinkForOfflineForm"
                  :value="false"
                  >Use built-in Offline Feedback Form
                </b-form-radio>
                <b-form-radio
                  class="mt-2"
                  :class="{
                    'text-muted': !formItem.useExternalLinkForOfflineForm,
                  }"
                  v-model="formItem.useExternalLinkForOfflineForm"
                  name="useExternalLinkForOfflineForm"
                  :value="true"
                  >Use external link for Offline Feedback Form
                </b-form-radio>
                <div class="pl-4">
                  <b-input
                    ref="externalLinkForOfflineForm"
                    type="url"
                    class="form-control-sm"
                    placeholder="e.g. https://offlineform.com/feedback-form"
                    v-model.trim="formItem.externalLinkForOfflineForm"
                    :disabled="!formItem.useExternalLinkForOfflineForm"
                  ></b-input>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Routing Rules</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-input
                class="form-control-sm d-inline-block mr-1"
                v-model.number="formItem.routingPercentage"
                style="max-width: 60px"
                type="number"
                min="0"
                max="100"
                required
                onpaste="return false;"
                @keypress="keypressDigitOnly"
                @keyup="zeroToHundredOnly($event)"
              />
              <small class="text-muted"
                >% of chats to be routed to bot (0-100%)</small
              >
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Routing Rules For VIP</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-input
                class="form-control-sm d-inline-block mr-1"
                v-model.number="formItem.routingPercentageForVIP"
                style="max-width: 60px"
                type="number"
                min="0"
                max="100"
                placeholder="0 - 100"
                required
                onpaste="return false;"
                @keypress="keypressDigitOnly"
                @keyup="zeroToHundredOnly($event)"
              />
              <small class="text-muted"
                >% of chats from VIP users to be routed to bot (0-100%)</small
              >
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Telegram Token</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-input
                class="form-control-sm"
                v-model.trim="formItem.telegramBotToken"
                max-length="200"
                placeholder="optional"
              />
              <small class="text-muted">
                token to be used to connect to telegram
              </small>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Additional Information</label>
            </b-col>
            <b-col cols="12" xl="9">
              <custom-values v-model="formItem.customValues" />
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" xl="3">
              <label>Bot Processor</label>
            </b-col>
            <b-col cols="12" xl="9">
              <b-form-select
                  size="sm"
                  v-model="formItem.processor"
                  :options="botProcessors"
              ></b-form-select>
            </b-col>
          </b-row>
<!--          <b-row class="my-2">-->
<!--            <b-col cols="12" xl="3">-->
<!--              <label>Bot Type</label>-->
<!--            </b-col>-->
<!--            <b-col cols="12" xl="9">-->
<!--              <b-form-select-->
<!--                size="sm"-->
<!--                v-model="formItem.botType"-->
<!--                :options="botTypes"-->
<!--              ></b-form-select>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--          <b-row v-if="formItem.botType === 2" class="my-2">-->
<!--            <b-col cols="12">-->
<!--              <bot-skype-configs v-model="botSkypeConfig" />-->
<!--            </b-col>-->
<!--          </b-row>-->
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mb-5">
      <b-col cols="12" xl="8">
        <b-button class="float-right" variant="primary" @click="promptConfirm"
          >Submit
        </b-button>
      </b-col>
    </b-row>
    <div v-if="isBusy" class="spinner-busy">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import CustomValues from '@/components/Intents/CustomValues';
import BotSelector from '@/components/UI/BotSelector';
import BotActivityLog from '@/components/Bots/BotActivityLog';
// import BotSkypeConfigs from '@/components/Bots/BotSkypeConfigs.vue';

export default {
  /*global _*/
  mixins: [httpclient],
  components: {
    BotSelector,
    CustomValues,
    BotActivityLog,
    // BotSkypeConfigs,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    if (botId) this.changeBot(botId);
  },
  data() {
    return {
      path: 'bot-settings',
      bots: [],
      languages: [
        { languageId: 'English', languageName: 'English' },
        { languageId: 'Chinese', languageName: 'Chinese' },
        { languageId: 'Vietnamese', languageName: 'Vietnamese' },
        { languageId: 'Thai', languageName: 'Thai' },
      ],
      botTypes: [
        { value: 0, text: 'Old Bot' },
        { value: 1, text: 'Mario' },
        { value: 2, text: 'Skype Bot' },
      ],
      botProcessors: [
        {value: 0, text: 'Mario'},
        {value: 1, text: 'GPT'},
        {value: 2, text: 'AI'},
      ],
      formItem: {
        botId: -1,
        botName: '',
        botLanguage: 'English',
        allowMemberDownloadHistory: false,
        enableOfflineForm: false,
        useExternalLinkForOfflineForm: false,
        externalLinkForOfflineForm: '',
        routingPercentage: 100,
        routingPercentageForVIP: 0,
        telegramBotToken: '',
        customValues: '[]',
        botType: 1,
        processor: 0,
        botSkypeConfig: null,
      },
      botSkypeConfig: {
        skypeUserName: '',
        skypePassword: '',
        skypeUserId: '',
        skypeDisplayName: '',
        csSkypeUserName: '',
        brand: '',
        messageEmptyRequest: '',
        messageFileDownloaded: '',
      },
    };
  },
  watch: {},
  methods: {
    getData() {},
    changeBot(botId) {
      if (botId) {
        this.formItem.botId = botId;
      }

      this.httpclient.get('/api/bots/' + this.formItem.botId).then((resp) => {
        if (resp.data) {
          const formItem = _.cloneDeep(resp.data);

          if (formItem.customValues) {
            let customObj = JSON.parse(formItem.customValues);
            if (_.isObject(customObj) && !_.isArray(customObj)) {
              let customValues = [];
              _.forEach(customObj, function (value, key) {
                customValues.push({ key: key, value: value });
              });
              formItem.customValues = customValues || [];
            } else {
              formItem.customValues = [];
            }
          } else {
            formItem.customValues = [];
          }

          if (formItem.botSkypeConfig) {
            this.botSkypeConfig = formItem.botSkypeConfig;
          } else {
            this.botSkypeConfig = _defaultBotSkypeConfig();
          }

          this.formItem = formItem;
          this.changeLanguage();
        }
      });
    },
    changeLanguage() {
      const lang = this.formItem.botLanguage;
      if (lang === 'English') {
        this.$i18n.locale = 'en';
      } else if (lang === 'Chinese') {
        this.$i18n.locale = 'zh';
      } else if (lang === 'Vietnamese') {
        this.$i18n.locale = 'vn';
      } else if (lang === 'Thai') {
        this.$i18n.locale = 'th';
      } else {
        this.$i18n.locale = 'en';
      }
    },
    promptConfirm() {
      const vm = this;

      const externalLinkForOfflineForm = vm.$refs['externalLinkForOfflineForm'];
      if (
        vm.formItem.enableOfflineForm && //use offline form
        vm.formItem.useExternalLinkForOfflineForm && //use external link for offline form
        !vm.formItem.externalLinkForOfflineForm //but url is empty
      ) {
        externalLinkForOfflineForm.setCustomValidity(
          'Url for the external link is required'
        );
      } else {
        externalLinkForOfflineForm.setCustomValidity('');
      }

      const mainForm = vm.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      const confirmMessage = 'Update the bot settings?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.editItem();
          } else {
            vm.$refs['form-modal'].show();
          }
        });
    },
    editItem() {
      const formItem = _.cloneDeep(this.formItem);

      //Process Custom Values
      let customObj = _.reduce(
        formItem.customValues,
        (obj, { key, value }) => ({ ...obj, [key]: value }),
        {}
      );
      formItem.customValues = JSON.stringify(customObj);
      if (formItem.botType === 2) formItem.botSkypeConfig = this.botSkypeConfig;

      this.httpclient.put('/api/bots', formItem).then(() => {
        this.$bvModal
          .msgBoxOk('Bot settings had been updated sucessfully', {
            okVariant: 'success',
          })
          .then(() => {
            this.changeBot();
          });
      });
    },
    keypressDigitOnly($event) {
      let charCode = $event.which ? $event.which : $event.keyCode;
      if (charCode < 48 || charCode > 57) {
        $event.preventDefault();
      }
    },
    zeroToHundredOnly($event) {
      let value = $event.target.value;
      if (value < 0) {
        $event.target.value = '0';
      }
      if (value > 100) {
        $event.target.value = '100';
      }
    },
  },
};

const _defaultBotSkypeConfig = () => {
  return {
    skypeUserName: '',
    skypePassword: '',
    skypeUserId: '',
    skypeDisplayName: '',
    csSkypeUserName: '',
    brand: '',
    messageEmptyRequest: '',
    messageFileDownloaded: '',
  };
};
</script>

<style lang="scss" scoped></style>
